<template>
    <section class="content">
        <table class="table table-hover table-striped table-no-border" ref="tablesuswa" id="tb_vsspec">
            <thead>
                <tr>
                    <th>Tryout</th>
                    <th>TANGGAL UJIAN</th>
                    <th>Skor</th>
                    <th>Bobot (%)</th>
                    <th>Skor Akhir (Skor x Bobot)</th>
                    <th>Opsi</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            filter: {},
            siswa: {},
            method: '',
            roles: '',
            id: '',
            branch: '',
            userid: '',
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.target.matches(".pembahasan")) {
                this.$router.push({ path: "/ujian-finish/" + e.target.dataset.id });
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "Rapor Evaluasi",
            "roles": ["read"],
            "ajax": "/siswa/ujian/hasil_ujian",
            "columns": [
                { "data": "ujian_title" },
                { "data": "tanggal",
                render: function (data, type, row, meta) {
                    return moment(data).format("DD/MM/YYYY hh:mm");
                } },
                { "data": "final_score" },
                { "data": "bobot" },
                { "data": "pembobotan" },
                { "data": "final_score" },
            ],
            filter: false,
            scrollX: true,
            paramData: function(d) {
                d.paket = 2;
                var filter = JSON.parse(JSON.stringify(self.$route.params.jenis));
                for (const key in filter) {
                    d[key] = filter[key] === false ? 0 : filter[key];
                }
            },
            rowCallback: function (row, data) {
                $("td:eq(5)", row).html(
                    '<button class="btn btn-outline-primary pembahasan" data-id="'+data.ujian_code+'">Pembahasan</button>'
                );
                // $('td:eq(0)', row).attr('rowspan', 3);
            },
            "fnDrawCallback": function(oSettings) {
                if (oSettings.aiDisplay.length == 0) {
                    return;
                }

                var nTrs = $('#tb_vsspec tbody tr');
                var iColspan = nTrs[0].getElementsByTagName('td').length;
                var sLastGroup = "";
                var total = 0;
                for (var i = 0; i < nTrs.length; i++) {
                    var iDisplayIndex = oSettings._iDisplayStart + i;
                    var paket = oSettings.aoData[oSettings.aiDisplay[iDisplayIndex]]._aData['paket_id'];
                    var nama_paket = oSettings.aoData[oSettings.aiDisplay[iDisplayIndex]]._aData['nama_paket'];
                    var final_score = oSettings.aoData[oSettings.aiDisplay[iDisplayIndex]]._aData['t_score'];
                    var final_bobot = oSettings.aoData[oSettings.aiDisplay[iDisplayIndex]]._aData['t_bobot'];

                    // nTrs[i].children[0].setAttribute('rowspan', rowspan);

                    if (paket != sLastGroup) {
                        var nGroup = document.createElement('tr');
                        var nCell = document.createElement('td');
                        nCell.setAttribute('style', "background-color:#92A9BD;color:white;font-size: initial;");
                        nCell.colSpan = iColspan;
                        nCell.className = "group";
                        nCell.innerHTML = `<b>` +nama_paket + `</b> 
                            <span style="float:right; margin-right: 10px">Skor Akhir : <span class="bdge bdge-info"> `+final_bobot+` </span></span>`;
                        nGroup.appendChild(nCell);
                        nTrs[i].parentNode.insertBefore(nGroup, nTrs[i]);
                        sLastGroup = paket;
                        total = final_score;
                    } else {
                        total = total + final_score;
                    }
                }
            }
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .table thead th {
        border-bottom:  none;
    }
    .table td, .table th {
        border-top:  none;   
    }
    .table td {
        vertical-align: middle;
    }
    .bdge-info {
        color: #fff;
        background-color: #7C99AC !important;
    }

    .bdge {
        display: inline-block;
        padding: .25em .4em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .4rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
</style>